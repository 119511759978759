import React, { useState, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import Logo from './img/renata-logo.svg';
import loadingGif from './img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/login.css';
import axios from 'axios';

function Login() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [password, setPassword] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState('');

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (event.target.value.trim()) {
      setPasswordError(false);
    }
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
  
    // Remove non-digit characters
    value = value.replace(/\D/g, '');
  
    // Check prefix and format accordingly
    const prefix = value.slice(0, 2);
    if (prefix === '55' || prefix === '56' || prefix === '33' || prefix === '81') {
      if (value.length > 2 && value.length <= 6) {
        value = value.slice(0, 2) + ' ' + value.slice(2);
      } else if (value.length > 6) {
        value = value.slice(0, 2) + ' ' + value.slice(2, 6) + ' ' + value.slice(6, 10);
      }
    } else {
      if (value.length > 3 && value.length <= 6) {
        value = value.slice(0, 3) + ' ' + value.slice(3);
      } else if (value.length > 6) {
        value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6, 10);
      }
    }
  
    // Update state 
    setIsValidNumber(value.length === 12);
    setPhoneNumber(value);
    if (event.target.value.trim()) {
      setPhoneError(false);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); 
    let isValid = true;
    setIsLoading(true);
  
    if (!isValidNumber) {
      setPhoneError(true);
      isValid = false;
    }
  
    if (!password.trim()) {
      setPasswordError(true);
      isValid = false;
    }
  
    if (!isValid) {
      setIsLoading(false);
      return;
    }

    axios.post('https://development2.goji-mx.cloud/backend/login', {
      phoneNumber: phoneNumber, 
      password: password
    })
    .then(response => {
      // Store session stuff
      localStorage.setItem('gojimx_token', response.data.token); 
      localStorage.setItem('gojimx_phone', response.data.phoneNumber); 
      localStorage.setItem('gojimx_username', response.data.username); 
      localStorage.setItem('gojimx_businessId', response.data.businessId); 
      localStorage.setItem('gojimx_business_name', response.data.business_name); 
      localStorage.setItem('gojimx_assistant_id', response.data.assistant_id); 

      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('gojimx_token')}`;

      setIsLoading(false);
      window.location.reload();

    })
    .catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          setLoginError('Credenciales incorrectas, por favor verifica tu número de teléfono y contraseña.');
        } else if (error.response.status === 500) {
          setLoginError('Error interno del servidor, por favor inténtalo nuevamente más tarde.');
        }
      } else {
        setLoginError('Se produjo un error, por favor verifica tu conexión a internet o inténtalo nuevamente más tarde.');
      }

      setIsLoading(false);
    });
  };
  
  return (
    <div className='flex-column align-items-center py-3 px-5'>
      <div className='row justify-content-center'>
        <div className='col-sm-12 col-md-5 col-lg-4 text-center'>
          <img className="w-50 mt-5" src={Logo} alt="Renata Logo" />
        </div>
      </div>
      
      <div className='row justify-content-center'>
        <div className="col-sm-12 col-md-4 col-lg-3 p-login">
          <h1 className='fw-bolder text-dark text-center mb-4 animate__animated animate__fadeInUp'>Inicia sesión</h1>
          <form noValidate className='w-100 p-0 animate__animated animate__fadeInUp' onSubmit={handleSubmit}>
            <div className="mb-3">
              <input 
                type="tel" 
                className={`form-control bg-light centered-placeholder centered-input-container d-flex ${phoneError ? 'is-invalid' : ''}`}
                placeholder='Número de teléfono' 
                onChange={handlePhoneChange}
                autoComplete="off"
                value={phoneNumber}
              />
              {phoneError && <div className="invalid-feedback">Por favor, introduce un teléfono válido.</div>}
            </div>
            <div className="mb-3">
              {isValidNumber && (
                <input 
                  type="password" 
                  className={`form-control bg-light centered-placeholder centered-input-container d-flex ${passwordError ? 'is-invalid' : ''}`}
                  placeholder='Contraseña' 
                  onChange={handlePasswordChange}
                  autoComplete="off"
                />
              )}
              {passwordError && <div className="invalid-feedback">Por favor, introduce una contraseña.</div>}
            </div>
            <button type="submit" className="btn btn-dark w-100" disabled={isLoading}>
              {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..."/> : 'Continuar'}
            </button>
            {loginError && <div className="alert alert-danger mt-3">{loginError}</div>}
            <div className='mt-2 text-center'>
              <span className='fs-6'>¿No tienes una cuenta? <Link to="/register" className="text-primary">Regístrate</Link></span>
            </div>
          </form>
        </div>
      </div>

    </div>
  );
}

export default Login;
