import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import './css/main.css';
import Menu from './Menu';
import TopBar from './TopBar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import renata_img from './img/renata.png';
import user_img from './img/user.png';
import axios from 'axios';

interface Message {
  id: string;
  object: string;
  created_at: number;
  assistant_id: string | null;
  thread_id: string;
  run_id: string | null;
  role: string;
  content: {
    type: string;
    text: {
      value: string;
      annotations: any[];
    };
  }[];
  file_ids: any[];
  metadata: Record<string, unknown>;
}

function Messages() {
  const gojimx_token = localStorage.getItem('gojimx_token');
  const gojimx_businessId = localStorage.getItem('gojimx_businessId');

  const [renata_sleep, setRenataSleep] = useState(false);

  const [show, setShow] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [searchParams] = useSearchParams();
  const threadId = searchParams.get('thread');
  const userName = searchParams.get('name');
  const userPhone = searchParams.get('phone');
  const userSummary = searchParams.get('summary');

  // Inside your component
  const conversationBoxRef = useRef<HTMLDivElement>(null); // Specify the type

  // Scroll to bottom function
  const scrollToBottom = () => {
    if (conversationBoxRef.current) {
      conversationBoxRef.current.scrollTop = conversationBoxRef.current.scrollHeight;
    }
  };

  function convertirAFecha(fechaEnSegundos: number) {
    const fechaEnMilisegundos = fechaEnSegundos * 1000;
    const fecha = new Date(fechaEnMilisegundos);
    const fechaFormateada = fecha.toLocaleString();
    return fechaFormateada;
  }

  function eliminarCorchetes(text: string) {
    if (text.startsWith('[RENATA MESSAGE]')) {
      return text;
    }
  
    const regex = /\[.*?\]/g;
    return text.replace(regex, "");
  }

  const fetchMessages = async (firstTime: boolean) => {
    const url = `https://development2.goji-mx.cloud/backend/get_messages/${threadId}`;
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${gojimx_token}`,
      },
    });
    if (!response.ok) {
      console.error("Network response was not ok");
      return;
    }

    const data = await response.json();
    
    setMessages(data.data.reverse());

    if(firstTime) {
      setTimeout(scrollToBottom, 200);
      if(data.renata_sleep) {
        setRenataSleep(data.renata_sleep);
      }
    }
  };

  const handleSendMessage = async () => {
    // Define the URL for the POST request
    const url = `https://development2.goji-mx.cloud/backend/addMessage`;
  
    // Prepare the data for the POST request, based on the expected structure
    const requestBody = {
      param_threadId: threadId, 
      business_id: gojimx_businessId, 
      whatsAppNumber: userPhone, 
      messageText: newMessage.trim()
    };
  
    try {
      // Make the POST request using axios
      const response = await axios.post(url, requestBody, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`, // Use the stored token
          'Content-Type': 'application/json',
        },
      });
  
      console.log('Message sent successfully:', response.data);
  
      // Clear the newMessage state to reset the input field
      setNewMessage('');
  
      // Optionally, fetch messages again to update the UI
      fetchMessages(false);
  
      // Scroll to the bottom of the conversation
      scrollToBottom();
    } catch (error) {
      // Log or handle errors here
      console.error('Error sending message:', error);
    }
  };
  
  async function handleRenataSleepChange(e: { target: { checked: any; }; }) {
    const newRenataSleepStatus = !e.target.checked;
    setRenataSleep(newRenataSleepStatus); // Update local state immediately for responsiveness
  
    // Define the URL for the POST request to update renata_sleep status
    const url = `https://development2.goji-mx.cloud/backend/updateRenataSleep`;
  
    // Prepare the data for the POST request, based on the expected structure
    const requestBody = {
      threadId: threadId, // Assuming threadId is available in the scope
      renata_sleep: newRenataSleepStatus,
    };
  
    try {
      // Make the POST request using axios
      await axios.post(url, requestBody, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`, // Use the stored token
          'Content-Type': 'application/json',
        },
      });
  
      // Notify the user that the renata_sleep status has been updated
      alert(`Renata ha sido ${newRenataSleepStatus ? 'apagada' : 'encendida'} en esta conversación.`);
    } catch (error) {
      // Log or handle errors here
      console.error('Error updating Renata sleep status:', error);
      // Optionally, revert the state if the update fails
      setRenataSleep(!newRenataSleepStatus); // Revert state change on error
      // Notify the user of the error
      alert('Failed to update Renata sleep status. Please try again.');
    }
  }
  
  
  useEffect(() => {
    fetchMessages(true);
  
    // Set up the interval
    const intervalId = setInterval(() => {
      fetchMessages(false);
    }, 7000); // every 7 seconds
  
    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [threadId]); // Depend on threadId to re-establish the interval if it changes
  

  return (
    <div className={show ? 'blur-effect pt-main' : 'pt-main'}>
      <div id="topbar">
        <TopBar handleShow={handleShow}/>
      </div>
      
      <div className='row justify-content-center'>
        <div className='col-lg-6 col-md-8 col-sm-12 py-4'>
          <div className='row px-3 animate__animated animate__fadeIn'>
            <div className='d-flex'>
              <h1 className='fw-bold text-dark mt-0 me-3'>{userName}</h1>
              <h1 className='fw-bold text-secondary mt-0'>{userPhone}</h1>
            </div>
            <div className='w-100 my-4'>
              <div className='border mb-1 animate__animated animate__fadeIn'></div>
            </div>

            <h4 className='fw-bold text-dark mt-0 me-3'>Resumen</h4>
            <p className='lead mt-0'>{userSummary}</p>

            <div className='row'>
              <div className='col-lg-2 col-md-3 col-sm-4 me-2'>
                <h4 className='me-3 fw-bold'>Renata</h4>
              </div>
              <div className='col-6 text-start'>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input large-checkbox mt-2"
                    type="checkbox"
                    checked={!renata_sleep}
                    onChange={handleRenataSleepChange} 
                  />
                </div>
              </div>
            </div>
            <div className='w-100 my-4'>
              <div className='border mb-2 animate__animated animate__fadeIn'></div>
            </div>
          </div>

          <div className='rounded py-4 conversation-box' ref={conversationBoxRef}>
            <div className='conversation-scroll'>
              {messages.map((message) => {
                const messageText = message.content[0].text.value;
                const messageClass = messageText.startsWith('[RENATA MESSAGE]') || message.role !== 'user'
                  ? 'message-item-2'
                  : 'message-item-1';
                const isRenataMessage = (message.role === 'user' && !messageText.startsWith('[RENATA MESSAGE]'));
                return (
                  <div key={message.id} className="row px-3 animate__animated animate__fadeIn">
                    <div className={`d-flex justify-content-${isRenataMessage ? 'start' : 'end'}`}>
                      {isRenataMessage ?  <img src={user_img} className='user_image rounded' alt="User" /> : ""}
                      <p className={`${messageClass} lead mb-0 p-3`}>
                        {eliminarCorchetes(messageText)}
                      </p>
                      {message.role !== 'user' || messageText.startsWith('[RENATA MESSAGE]') ? <img src={renata_img} className='renata_image rounded' alt="Renata" /> : ""}
                    </div>
                    <div className={`d-flex justify-content-${isRenataMessage  ? 'start' : 'end'}`}>
                      <p className={`text-secondary pt-0 mt-0 mb-3 ${isRenataMessage ? 'me-date2' : 'me-date'}`}>{convertirAFecha(Number(message.created_at))}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className='mt-2 d-flex ps-4'>
            <div className='row w-100 border border-2 rounded'>
              <div className="col-10 px-1">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-custom py-2"
                  placeholder="Escribe tu mensaje..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
              </div>
              <div className="col-2 ps-0 pe-2 py-2">
                <Button variant="dark" className='w-100' disabled={!newMessage.trim()} onClick={handleSendMessage}>
                  <i className="bi bi-send-fill"></i>
                </Button>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <Menu show={show} handleClose={handleClose} />
    </div>
  );
}

export default Messages;
