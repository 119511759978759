import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Login from './Login';
import Register from './Register';
import Home from './Home';
import MySchedule from './MySchedule';
import Settings from './Settings';
import MyConversations from './MyConversations';
import Messages from './Messages';
import Statistics from './Statistics';

function App() {
  const token = localStorage.getItem('gojimx_token'); // Obtiene el token del almacenamiento local

  return (
    <div style={{ overflowX: 'hidden' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router>
          <Routes>
            <Route 
              path="/"
              element={token ? <Navigate to="/home" /> : <Login />} 
            />
            <Route 
              path="/register" 
              element={token ? <Navigate to="/home" /> : <Register />} 
            />
            <Route
              path="/login"
              element={token ? <Navigate to="/home" /> : <Login />}
            />
            <Route
              path="/home"
              element={token ? <Home /> : <Navigate to="/login" />}
            />
            <Route 
              path="/myschedule"
              element={token ? <MySchedule /> : <Navigate to="/login" />}
            />
            <Route 
              path="/myconversations"
              element={token ? <MyConversations /> : <Navigate to="/login" />}
            />
            <Route 
              path="/settings"
              element={token ? <Settings /> : <Navigate to="/login" />}
            />
            <Route 
              path="/messages"
              element={token ? <Messages /> : <Navigate to="/login" />}
            />
            <Route 
              path="/statistics"
              element={token ? <Statistics /> : <Navigate to="/login" />}
            />
          </Routes>
        </Router>
      </LocalizationProvider>
    </div>
  );
}

export default App;
