import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './css/main.css';
import Menu from './Menu';
import TopBar from './TopBar';
import { getAppointments, validateToken, decodeUnicode } from './functions/functions';
import { DateCalendar } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { utcToZonedTime } from 'date-fns-tz';
import axios from 'axios';
import loadingGif from './img/loading.gif';
import WeekView from './MyScheduleWeek';

// Update the Spanish locale to start the week on Sunday
import 'dayjs/locale/es';
dayjs.extend(updateLocale);
dayjs.updateLocale('es', {
  weekStart: 0
});
dayjs.locale('es');

interface Appointment {
  _id: string;
  userid: string;
  date: string;
  time: string;
  fullName: string;
  reason: string;
  business_id: string;
  expired: boolean;
}

function MySchedule() {
  const gojimx_businessId = localStorage.getItem('gojimx_businessId');
  const gojimx_phone = localStorage.getItem('gojimx_phone'); 

  const [viewType, setviewType] = useState("Día");
  const [showAddModal, setShowAddModal] = useState(false);
  const [show, setShow] = useState(false);
  const [selected_date, setSelectedDate] = useState(new Date());
  const [date_label, setDateLabel] = useState("");
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [newAppointment, setNewAppointment] = useState({
    fullName: '',
    date: '',
    time: '',
    reason: '',
  });
  const [isLoading, setisLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingAppointment, setEditingAppointment] = useState<Appointment | null>(null);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleShowAddModal = () => setShowAddModal(true);
  const handleCloseAddModal = () => setShowAddModal(false);
  
  const handleOpenDeleteModal = (appointmentId: string) => {
    setAppointmentToDelete(appointmentId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setAppointmentToDelete('');
  };

  const confirmDeleteAppointment = () => {
    setisLoading(true);
    deleteAppointment(appointmentToDelete);
    setisLoading(false);
    handleCloseDeleteModal();
  };

  const handleOpenEditModal = (appointment: any) => {
    setEditingAppointment(appointment);
    setShowEditModal(true);
  };
  
  // Custom date formatting function
  const formatDate = (date: Date) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} de ${month} ${year}`;
  };

  const getLabelForDate = (date: Date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (date.toDateString() === today.toDateString()) {
      return "Hoy";
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Ayer";
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return "Mañana";
    } else {
      return formatDate(date);
    }
  };

  function Year_Month_Day_String(pDate: Date) {
    const year = pDate.getFullYear();
    const month = (pDate.getMonth() + 1).toString().padStart(2, '0');
    const day = pDate.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  const handlePrev = () => {
    setSelectedDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 1);
      return newDate;
    });
  };
  
  const handleNext = () => {
    setSelectedDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 1);
      return newDate;
    });
  };

  const handleChangeView = () => {
    if(viewType === "Día"){ //
      setviewType("Semana");
    }
    else {
      setviewType("Día");
    }
  };

  const weekLabel = (pSelected_date: Date) => {
    let DateString = formatDate(new Date(pSelected_date));
    let regex = /de\s(.*)/;
    let match = DateString.match(regex);

    if (match && match[1]) {
        return match[1];
    } else {
      return "";
    }
  };

  

  const handleAdd = () => {
    setisLoading(true); // Start loading
    const apiUrl = 'https://development2.goji-mx.cloud/backend/addAppointment';
    const token = localStorage.getItem('gojimx_token');

    axios.post(apiUrl, {
      ...newAppointment,
      user_id: String(gojimx_phone),
      business_id: String(gojimx_businessId),
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      console.log('Appointment added:', response.data);
      handleCloseAddModal();
      setNewAppointment({ fullName: '', date: '', time: '', reason: '' }); // Clear fields
      fetchAppointments();
      setSelectedDate(selected_date); //update UI
    })
    .catch(error => {
      console.error('Error adding appointment:', error);
    })
    .finally(() => {
      setisLoading(false); // Stop loading regardless of the outcome
    });
  };

  const deleteAppointment = (appointmentId: any) => {
    const apiUrl = `https://development2.goji-mx.cloud/backend/removeAppointment/${appointmentId}`;
    const token = localStorage.getItem('gojimx_token');

    axios.delete(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      console.log('Appointment deleted:', response.data);
      fetchAppointments(); // Refresh the appointments list
      setSelectedDate(selected_date); //update UI
    })
    .catch(error => {
      console.error('Error deleting appointment:', error);
    });
  };

  const handleUpdateAppointment = () => {
    if (editingAppointment) {
      setisLoading(true);
      const apiUrl = `https://development2.goji-mx.cloud/backend/updateAppointment/${editingAppointment._id}`;
      const token = localStorage.getItem('gojimx_token');
  
      axios.patch(apiUrl, editingAppointment, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        // handle success
        setShowEditModal(false);
        fetchAppointments(); // refresh appointments list
      })
      .catch(error => {
        // handle error
      })
      .finally(() => {
        setisLoading(false);
        setSelectedDate(selected_date); //update UI
      });
    } else {
      // Handle the case where editingAppointment is null
      console.error("Error: No appointment is currently being edited.");
    }
  };
  
  // Function to fetch appointments
  const fetchAppointments = () => {
    if(viewType === "Día") {
      getAppointments(Year_Month_Day_String(selected_date), String(gojimx_businessId))
      .then(res_appointments => {
        // Sort the appointments by time
        const sortedAppointments = res_appointments.appointments.sort((a: { time: string; }, b: { time: any; }) => {
          return a.time.localeCompare(b.time);
        });
        setAppointmentStatus(sortedAppointments);
      })
      .catch(error => {
        console.error(error);
      });
    }
  };
  
  // Modal Handling
  const handleOpenCalendarModal = () => setShowCalendarModal(true);
  
  const handleCloseCalendarModal = () => setShowCalendarModal(false);

  const handleDateChange = (newValue: Dayjs | null) => {
    setSelectedDate(newValue ? newValue.toDate() : new Date());
    handleCloseCalendarModal();
  };

  // Function to determine the current appointment based on current time
  const setAppointmentStatus = (pAppointments: any) => {
    const mexicoCityTimeZone = 'America/Mexico_City';
    const nowUtc = new Date();
    const nowInMexicoCity = utcToZonedTime(nowUtc, mexicoCityTimeZone);
  
    let updatedAppointments = pAppointments.map((appointment: { date: string; time: string; }) => {
      const this_appointmentTime = new Date(appointment.date + ' ' + appointment.time);
      const isExpired = this_appointmentTime < nowInMexicoCity;
      return {
        ...appointment,
        expired: isExpired
      };
    });

    setAppointments(updatedAppointments);
  };
  

  useEffect(() => {
    const checkToken = async () => {
      const isValidToken = await validateToken();
      if (!isValidToken) {
        // Manejar el caso de token inválido si es necesario
        console.log('Token inválido o no encontrado');
      } else {
        
        const label = getLabelForDate(selected_date);
        setDateLabel(label);
      
        getAppointments(Year_Month_Day_String(selected_date), String(gojimx_businessId))
        .then(res_appointments => {
          // Sort the appointments by time
          const sortedAppointments = res_appointments.appointments.sort((a: { time: string; }, b: { time: any; }) => {
            return a.time.localeCompare(b.time);
          });
          setAppointmentStatus(sortedAppointments);
        })
        .catch(error => {
          console.error(error)
        });

      }
    };

    checkToken();

  }, [selected_date]);

  return (
    <div className={show ? 'blur-effect pt-main' : 'pt-main'}>
      
      <div id="topbar">
        <TopBar handleShow={handleShow}/>
      </div>

      <div className='row justify-content-center'>
        <div className='col-lg-8 col-md-10 col-sm-12 px-5 py-4'>
          
          <div className='row'>
            <div onClick={handleOpenCalendarModal} className='d-flex col-lg-8 col-md-8 col-sm-12 px-3 animate__animated animate__fadeIn border border-2 rounded py-3 mb-4 clickable'>
              <div className='col-2 d-flex justify-content-center align-items-center'>
                {viewType === "Día" && (
                  <i className="bi bi-calendar-check" style={{ fontSize: '3em' }}></i>
                )}
                {viewType === "Semana" && (
                  <i className="bi bi-calendar-minus" style={{ fontSize: '3em' }}></i>
                )}
              </div>
              <div className='col-10 d-flex align-items-center'>
                <div>
                  <h4 className='text-secondary mb-0 ps-3'>Mi Agenda</h4>
                  {viewType === "Día" && (
                    <h2 className='fw-bold text-dark mt-0 ps-3'>{date_label}</h2>
                  )}
                  {viewType === "Semana" && (
                    <h2 className='fw-bold text-dark mt-0 ps-3'>{weekLabel(selected_date)}</h2>
                  )}
                  
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-4 col-sm-12 px-0'>
              <div className="col-12 ps-3 d-none d-md-block mb-3">
                <div onClick={handleChangeView} className="btn-lg p-1 text-center animate__animated animate__fadeIn border border-2 rounded w-100 clickable">
                  <h5 className='text-secondary p-1'>
                    {viewType === "Día" && (
                      <i className="bi bi-calendar-check me-2"></i>
                    )}
                    {viewType === "Semana" && (
                      <i className="bi bi-calendar-minus me-2"></i>
                    )}
                    {viewType} 
                  </h5> 
                </div>
              </div>

              <div className='d-flex p-0 m-0'>
                <div className="col-6 pe-3 pe-md-0 ps-md-3">
                  <div onClick={handlePrev} className="btn-lg p-1 text-center animate__animated animate__fadeIn border border-2 rounded w-100 clickable">
                    <i className="bi bi-chevron-left" style={{ fontSize: '1.2em' }}></i> 
                  </div>
                </div>
                <div className="col-6 ps-3">
                  <div onClick={handleNext} className="btn-lg p-1 text-center animate__animated animate__fadeIn border border-2 rounded w-100 clickable">
                    <i className="bi bi-chevron-right" style={{ fontSize: '1.2em' }}></i> 
                  </div>
                </div>
              </div>
              
              
              

            </div>
          </div>
          
          {viewType === "Día" && (
          <>


            {appointments.length > 0 && (
              <div className='w-100 my-4'>
                <p className='lead'>Todas las citas:</p>
              </div>
            )}

            {/* Display the appointments */}
            {appointments.length > 0 && appointments.map((appointment, index) => (
            <div 
              key={appointment._id} 
              className={`row p-3 mb-4 animate__animated animate__fadeIn border-start border-5 bg-light ${ appointment.expired ? 'border-secondary' : 'border-primary' } rounded`}
            >
              {/* This column will be full width on xs screens and 10 columns wide on sm and larger screens */}
              <div className='col-12 col-sm-10 ps-2 m-0'>
                <h1 className='text-secondary mb-0'>{appointment.time}</h1>
                <h4 className='text-dark mb-0 fw-bolder'>{decodeUnicode(appointment.fullName)}</h4>
                <p className='text-dark lead'>{decodeUnicode(appointment.reason)}</p>
              </div>    
            
              {/* These columns will be hidden on xs screens and visible on sm and larger screens */}
              <div className='col-1 d-none d-sm-flex p-0 m-0 justify-content-center align-items-center cita-option'>
                <div className='p-3 clickable rounded' onClick={() => handleOpenEditModal(appointment)}>
                  <i className="bi bi-pencil" style={{ fontSize: '1.2em' }}></i> 
                </div>
              </div> 
              <div className='col-1 d-none d-sm-flex p-0 m-0 justify-content-center align-items-center cita-option'>
                <div className='p-3 clickable rounded' onClick={() => handleOpenDeleteModal(appointment._id)}>
                  <i className="bi bi-trash3" style={{ fontSize: '1.2em' }}></i> 
                </div>
              </div>      
            </div>
            
              ))}

            {/* No appointments message */}
            {appointments.length === 0 && (
              <div className='w-100 my-4'>
                <p className='lead'>No hay nada agendado para este día.</p>
              </div>
            )}


          </>
          )}


          {viewType === "Semana" && (
            <WeekView current_date={selected_date}></WeekView>
          )}

          

          
          

        </div>
      </div>

      <Modal show={showCalendarModal} onHide={handleCloseCalendarModal}>
        <Modal.Header closeButton >
          <Modal.Title className='fw-bold'>📅 Seleccione una Fecha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DateCalendar 
            value={dayjs(selected_date)}
            onChange={handleDateChange}
          />
        </Modal.Body>        
      </Modal>

      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>📅 Añadir una Cita</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className='fw-bold text-dark mb-2'>Datos del paciente</h5>
          <p className='text-dark mb-2'>Nombre y apellido</p>
          <div>
            <input 
                type="text" 
                className="form-control mb-3"
                placeholder="Ej. Juan Pérez" 
                autoComplete="off"
                value={newAppointment.fullName}
                onChange={e => setNewAppointment({ ...newAppointment, fullName: e.target.value })}
            />
          </div>
          <h5 className='fw-bold text-dark mb-2'>Datos de la cita</h5>
          <p className='text-dark mb-2'>Fecha</p>
          <div>
            <input 
              type="date" 
              className="form-control mb-2"
              autoComplete="off"
              value={newAppointment.date}
              onChange={e => setNewAppointment({ ...newAppointment, date: e.target.value })}
            />
          </div>
          <p className='text-dark mb-2'>Horario</p>
          <div>
            <input 
                type="time" 
                className="form-control mb-2"
                placeholder="Ej. 11AM" 
                autoComplete="off"
                value={newAppointment.time}
                onChange={e => setNewAppointment({ ...newAppointment, time: e.target.value })}
            />
          </div>
          <p className='text-dark mb-2'>Motivo</p>
          <div>
            <input 
                type="text" 
                className="form-control mb-2"
                placeholder="Ej. Consulta general" 
                autoComplete="off"
                value={newAppointment.reason}
                onChange={e => setNewAppointment({ ...newAppointment, reason: e.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseAddModal}>
            Cancelar
          </Button>
          <Button variant="dark px-3 w-25" onClick={handleAdd} disabled={isLoading}>
            {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..."/> : 'Añadir'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>🗑️ Eliminar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro de que quieres eliminar esta cita?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseDeleteModal}>
            Cancelar
          </Button>
          <Button variant="danger px-3 w-25" onClick={confirmDeleteAppointment} disabled={isLoading}>
            {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..."/> : 'Eliminar'}
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>✏️ Editar Cita</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className='fw-bold text-dark mb-2'>Datos del paciente</h5>
          <p className='text-dark mb-2'>Nombre y apellido</p>
          <div>
            <input 
                type="text" 
                className="form-control mb-3"
                placeholder="Ej. Juan Pérez" 
                value={editingAppointment?.fullName || ''}
                onChange={e => setEditingAppointment(editingAppointment ? { ...editingAppointment, fullName: e.target.value } : null)}
            />
          </div>
          <h5 className='fw-bold text-dark mb-2'>Datos de la cita</h5>
          <p className='text-dark mb-2'>Fecha</p>
          <div>
            <input 
              type="date" 
              className="form-control mb-2"
              value={editingAppointment?.date || ''}
              onChange={e => setEditingAppointment(editingAppointment ? { ...editingAppointment, date: e.target.value } : null)}
            />
          </div>
          <p className='text-dark mb-2'>Horario</p>
          <div>
            <input 
                type="time" 
                className="form-control mb-2"
                value={editingAppointment?.time || ''}
                onChange={e => setEditingAppointment(editingAppointment ? { ...editingAppointment, time: e.target.value } : null)}
            />
          </div>
          <p className='text-dark mb-2'>Motivo</p>
          <div>
            <input 
                type="text" 
                className="form-control mb-2"
                placeholder="Ej. Consulta general" 
                value={editingAppointment?.reason || ''}
                onChange={e => setEditingAppointment(editingAppointment ? { ...editingAppointment, reason: e.target.value } : null)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowEditModal(false)}>
            Cancelar
          </Button>
          <Button variant="dark px-3 w-25" onClick={handleUpdateAppointment} disabled={isLoading}>
            {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..."/> : ' Guardar'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Menu show={show} handleClose={handleClose} />

      <div className={show ? 'd-none' : 'btn-add text-end pe-4'}>
        <div className='btn btn-lg btn-dark px-3 rounded' onClick={handleShowAddModal}>
          <i className="bi bi-plus-lg" style={{ fontSize: '1.4em' }}></i>
        </div>
      </div>

      
    </div>
  );
}

export default MySchedule;
