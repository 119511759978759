import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import './css/main.css';
import Menu from './Menu';
import TopBar from './TopBar';
import { exportExampleFormat, exportToExcel, handleFileUpload  } from './functions/excel'; // Ajusta la ruta según sea necesario
import { validateToken } from './functions/functions';
import loadingGif from './img/loading.gif';
import AdvancedFilters from './AdvancedFilters';

interface Conversation {
  category: string;
  last_message_date: string,
  user_name: string;
  id: string;
  threadId: string;
  business_id: string;
  summary: string;
  user_phone: string;
  renata_sleep: boolean;
}

interface CategoryIcons {
  [key: string]: string | undefined;
}

function MyConversations() {
  const gojimx_businessId = localStorage.getItem('gojimx_businessId');
  const gojimx_token = localStorage.getItem('gojimx_token');

  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [categoryIcons, setCategoryIcons] = useState<CategoryIcons>({});

  const [uploaded_conversations, setUploadedConversations] = useState<Conversation[]>([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalMensaje, setShowModalMensaje] = useState(false);
  const [showModalCarga, setShowModalCarga] = useState(false);

  const [opened_conversation, setOpenedConversation] = useState<Conversation>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const selectedConversations = conversations.filter(conversation => selectedIds.includes(conversation.id));

  const [isLoading, setIsLoading] = useState(false); 
  const [buttonText, setButtonText] = useState('Cargar');

  const [messageToSend, setMessageToSend] = useState('');

  /*FILTER STATES*/
  const [filterFecha, setFilterFecha] = useState<string | null>(null); 
  const [filterEstado, setFilterEstado] = useState<string | null>(null); 
  const [filterRenataState, setFilterRenataState] = useState<string | null>(null); 
  const [filterCategory, setFilterCategory] = useState<string | null>(null); 

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleCloseModal = () => setShowModal(false);
  const handleCloseModalCarga = () => setShowModalCarga(false);
  const handleCloseModalMensaje = () => setShowModalMensaje(false);

  const handleMessageChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setMessageToSend(e.target.value);
  };

  function handleSelectConversation(id: string) {
    setSelectedIds(prevSelectedIds => {
      if (prevSelectedIds.includes(id)) {
        // Si el id ya está seleccionado, lo quitamos
        return prevSelectedIds.filter(selectedId => selectedId !== id);
      } else {
        // Si el id no está seleccionado, lo agregamos
        return [...prevSelectedIds, id];
      }
    });
  }

  function handleShowModal(conv: Conversation) {
    setOpenedConversation(conv); // Establece la conversación abierta
    setShowModal(true); // Muestra el modal
  }
 
  function handleShowModalCarga() {
    setShowModalCarga(true); // Muestra el modal
  }

  function handleShowModalMensaje() {
    setShowModalMensaje(true); // Muestra el modal
  }

  const fetchConversations = () => {
    axios.get(`https://development2.goji-mx.cloud/backend/get_conversations/${gojimx_businessId}`, {
      headers: {
        Authorization: `Bearer ${gojimx_token}`
      }
    })
      .then(response => {
        setConversations(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the conversations:', error);
      });
  };

  const fetchCategories = () => {
    axios.get(`https://development2.goji-mx.cloud/backend/get_category/${gojimx_businessId}`, {
      headers: {
        Authorization: `Bearer ${gojimx_token}`
      }
    })
    .then(response => {
        // Assuming response.data[0] is the category document you want to use
        const options = response.data[0][0].options;
        const iconsMapping = options.reduce((acc: { [x: string]: any; }, option: { option_name: string | number; icon: any; }) => {
            acc[option.option_name] = option.icon;
            return acc;
        }, {});

        setCategoryIcons(iconsMapping);
    })
    .catch(error => {
        console.error('There was an error fetching the categories:', error);
    });
  };


  const uploadConversations = async () => {
    let loadedCount = 0; // Contador para el número de conversaciones cargadas
    const totalConversations = uploaded_conversations.length; // Total de conversaciones a cargar
    
    setIsLoading(true); // Inicia la carga
    
    const updateButtonText = () => {
      loadedCount++; // Incrementa por cada conversación cargada
      setButtonText(`${loadedCount} de ${totalConversations}`); // Actualiza el texto del botón
    };
  
    // Modifica cada promesa para resolver con un objeto de resultado en lugar de rechazar directamente
    const promises = uploaded_conversations.map(conversation => {
      const conversationWithBusinessId = { ...conversation, business_id: gojimx_businessId };
      return axios.post('https://development2.goji-mx.cloud/backend/addConversation', conversationWithBusinessId, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        updateButtonText(); // Actualiza el texto del botón después de cada carga exitosa
        return { success: true, data: response.data }; // Resultado exitoso
      })
      .catch(error => {
        updateButtonText(); // Asegúrate de actualizar el contador incluso en caso de error
        return { success: false, error: error.response.data, conversation: conversationWithBusinessId }; // Resultado fallido
      });
    });
  
    try {
      const results = await Promise.all(promises);
      // Filtra y alerta sobre los errores
      const errors = results.filter(result => !result.success);
      if (errors.length > 0) {
        const errorMessages = errors.map((error, index) => {
          // Verifica explícitamente si el objeto de error tiene la propiedad 'error'
          if ('error' in error) {
            return `Error ${index + 1}: ${error.error}: ${JSON.stringify(error.conversation.user_phone)}`;
          } else {
            return `Error ${index + 1}: Error desconocido.`;
          }
        }).join('\n');
        alert('Algunas conversaciones no se pudieron cargar:\n' + errorMessages);
      } else {
        alert('Todas las conversaciones han sido cargadas exitosamente.');
      }

      fetchConversations(); // Recargar la lista de conversaciones.
    } catch (error) {
      // Este bloque catch ya no es necesario para capturar errores en las promesas,
      // pero se deja por si hay otros errores inesperados.
      console.error('Error inesperado:', error);
    } finally {
      setIsLoading(false); // Finaliza la carga
      setButtonText('Cargar'); // Restablece el texto del botón a su estado original
      handleCloseModalCarga();
    }
  };
  
  function sendMessages() {
    // Asegúrate de que hay un mensaje para enviar
    if (!messageToSend.trim()) {
      alert('El mensaje no puede estar vacío.');
      return;
    }
  
    setIsLoading(true); 
  
    // Crear un array de promesas basado en las conversaciones seleccionadas
    const sendMessagePromises = selectedConversations.map(conversation => {
      // Prepara el cuerpo de la solicitud para cada conversación
      const requestBody = {
        param_threadId: conversation.threadId,
        business_id: gojimx_businessId,
        whatsAppNumber: conversation.user_phone,
        messageText: messageToSend.trim()
      };
  
      // Hace la solicitud POST para enviar el mensaje
      return axios.post('https://development2.goji-mx.cloud/backend/addMessage', requestBody, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`, 
          'Content-Type': 'application/json'
        }
      }).then(response => ({
        success: true,
        data: response.data
      })).catch(error => ({
        success: false,
        error: error.response ? error.response.data : 'Error desconocido',
        conversationId: conversation.id
      }));
    });
  
    // Usa Promise.all para esperar a que todas las solicitudes se completen
    Promise.all(sendMessagePromises)
      .then(results => {
        // Aquí puedes manejar las respuestas, por ejemplo, mostrar un mensaje de éxito o error
        const failedMessages = results.filter(result => !result.success);
        if (failedMessages.length > 0) {
          // Manejo de errores para los mensajes que fallaron
          alert(`Algunos mensajes no se pudieron enviar. Total fallos: ${failedMessages.length}`);
        } else {
          // Todos los mensajes se enviaron con éxito
          alert('Todos los mensajes se enviaron con éxito.');
        }
      })
      .catch(error => {
        // Manejo de un error inesperado durante el proceso de envío
        console.error('Error inesperado:', error);
        alert('Ocurrió un error inesperado al enviar los mensajes.');
      })
      .finally(() => {
        setIsLoading(false);
        setMessageToSend(''); 
        handleCloseModalMensaje(); 
      });
  }
  
  const handleSearchChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setSearchTerm(e.target.value);
  };

  const now = new Date();
  const startOfWeek = new Date(now);
  startOfWeek.setDate(now.getDate() - now.getDay() + 1); 
  startOfWeek.setHours(0, 0, 0, 0); // Set to the beginning of the day for accurate comparison
  const filteredConversations = conversations.filter(conversation => {
    const matchesSearchTerm = conversation.user_name.toLowerCase().includes(searchTerm.toLowerCase()) || conversation.user_phone.includes(searchTerm);
    
    // Determine if the conversation matches the active state filter
    let matchesEstadoFilter = true; // Assume true by default
    if (filterEstado) {
      if (filterEstado === 'Activas') {
        matchesEstadoFilter = isActive(conversation.last_message_date);
      } else if (filterEstado === 'Inactivas') {
        matchesEstadoFilter = !isActive(conversation.last_message_date);
      }
    }
  
    // Existing logic for date filtering
    const conversationDate = new Date(conversation.last_message_date);
    const now = new Date();
    let matchesDateFilter = true; // Initialize as true by default
    
    switch (filterFecha) {
      case 'hoy':
        matchesDateFilter = conversationDate.toDateString() === now.toDateString();
        break;
      case 'esta semana':
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week (Sunday)
        matchesDateFilter = conversationDate >= startOfWeek && conversationDate <= endOfWeek;
        break;
      case 'este mes':
        matchesDateFilter = conversationDate.getMonth() === now.getMonth() && conversationDate.getFullYear() === now.getFullYear();
        break;
      case 'este año':
        matchesDateFilter = conversationDate.getFullYear() === now.getFullYear();
        break;
      default:
        break;
    }

    // Filtering based on Renata's sleep state
    let matchesRenataState = true; // Assume true if not filtering by Renata state
    if (filterRenataState === 'renata_sleep') {
      matchesRenataState = conversation.renata_sleep === true;
    } else if (filterRenataState === 'renata_on') {
      matchesRenataState = conversation.renata_sleep !== true;
    }

    // Filtering based on Renata's sleep state
    let matchesCategory = true; // Assume true if not filtering by Renata state
    if (filterCategory != null) {
      matchesCategory = conversation.category === filterCategory;
    }
  
    return matchesSearchTerm && matchesDateFilter && matchesEstadoFilter && matchesRenataState && matchesCategory;
  });
  
  function isActive(fechaStr: string): boolean {
    const fecha = new Date(fechaStr);
    const fechaActual = new Date();
    const diferencia = fechaActual.getTime() - fecha.getTime();
    const horas = diferencia / (1000 * 60 * 60);
    return horas <= 24;
  }

  function selectAll() {
    if (filteredConversations.length === selectedIds.length) {
      setSelectedIds([]);
    } else {
      const allIds = filteredConversations.map(conversation => conversation.id);
      setSelectedIds(allIds);
    }
  }
  
  function isConversationActive(conversation: Conversation): boolean {
    if (!conversation.threadId) return false; // No activa si no hay threadId
    
    const lastMessageDate = new Date(conversation.last_message_date);
    const now = new Date();
    const differenceInHours = (now.getTime() - lastMessageDate.getTime()) / (1000 * 3600);
    
    return differenceInHours <= 23; // Activa si el último mensaje fue hace menos de 23 horas
  }
  
  // Determina si al menos una conversación seleccionada es inactiva
  const hasInactiveConversation = selectedConversations.some(conv => !isConversationActive(conv));

  const sendTemplateMessage = async () => {
    // Verificar si hay conversaciones seleccionadas
    if (selectedConversations.length === 0) {
      alert('Por favor, selecciona al menos una conversación.');
      return;
    }
  
    setIsLoading(true);
  
    //FER ROBLES FIX
    let template_id = "renata_hola";
    if(String(gojimx_businessId) == "307420139111106") {
      template_id = "fer_robles";
    }

    const sendMessagePromises = selectedConversations.map((conversation) => {
      const requestBody = {
        business_id: String(gojimx_businessId), // Utiliza el ID de negocio almacenado en localStorage
        whatsAppNumber: String(conversation.user_phone), // Número de WhatsApp de la conversación seleccionada
        templateName: template_id, // Nombre de la plantilla
        templateLanguage: "es_MX", // Idioma de la plantilla
        templateParameters: [
          String(messageToSend)
        ], // Parámetros de la plantilla
      };
  
      return axios.post('https://development2.goji-mx.cloud/backend/addMessageTemplate', requestBody, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`, // Asegúrate de tener un token válido aquí
          'Content-Type': 'application/json',
        },
      }).then(response => ({
        success: true,
        data: response.data,
      })).catch(error => ({
        success: false,
        error: error.response ? error.response.data : 'Error desconocido',
        conversationId: conversation.id,
      }));
    });
  
    // Usa Promise.all para esperar a que todas las solicitudes se completen
    Promise.all(sendMessagePromises)
      .then(results => {
        const failedMessages = results.filter(result => !result.success);
        if (failedMessages.length > 0) {
          alert(`Algunos mensajes de plantilla no se pudieron enviar. Total fallos: ${failedMessages.length}`);
        } else {
          alert('Todos los mensajes de plantilla se enviaron con éxito.');
        }
      })
      .catch(error => {
        console.error('Error inesperado:', error);
        alert('Ocurrió un error inesperado al enviar los mensajes de plantilla.');
      })
      .finally(() => {
        setIsLoading(false);
        setMessageToSend('');
        handleCloseModalMensaje();
      });
  };
  
  async function handleRenataSleepChange(e: { target: { checked: any; }; }) {
    const newRenataSleepStatus = !e.target.checked;
  
    // Ensure opened_conversation is not null or undefined
    if(opened_conversation) {
      // Create a new object with the updated renata_sleep value
      const updatedConversation = { ...opened_conversation, renata_sleep: newRenataSleepStatus };
  
      // Update the state to trigger a re-render
      setOpenedConversation(updatedConversation);
  
      const url = `https://development2.goji-mx.cloud/backend/updateRenataSleep`;
      const requestBody = {
        threadId: updatedConversation.threadId, 
        renata_sleep: newRenataSleepStatus,
      };
  
      try {
        await axios.post(url, requestBody, {
          headers: {
            Authorization: `Bearer ${gojimx_token}`, 
            'Content-Type': 'application/json',
          },
        });
        alert(`Renata ha sido ${newRenataSleepStatus ? 'apagada' : 'encendida'} en esta conversación.`);
        fetchConversations();
      } catch (error) {
        console.error('Error updating Renata sleep status:', error);
        alert('Failed to update Renata sleep status. Please try again.');
        window.location.reload();
      }
    }
  }
  
  useEffect(() => {
    const checkToken = async () => {
      const isValidToken = await validateToken();
      if (!isValidToken) {
        // Manejar el caso de token inválido si es necesario
        console.log('Token inválido o no encontrado!');
      } else {
        fetchConversations();
        fetchCategories();
      }
    }

    checkToken();
  }, []);

  return (
    <div className={show ? 'blur-effect pt-main' : 'pt-main'}>
      <div id="topbar">
        <TopBar handleShow={handleShow}/>
      </div>
      
      <div className='row justify-content-center'>
        <div className='col-lg-6 col-md-8 col-sm-12 py-4'>
          
        <div className='row px-3 animate__animated animate__fadeIn d-flex'>
          <div className="col-9">
            <h1 className='fw-bold text-dark mt-0'>Mis Conversaciones</h1>
          </div>
          <div className="col-3 d-flex justify-content-end">
            <div className='btn btn-light float-end me-3' title="Cargar conversaciones" onClick= {() => { handleShowModalCarga() }}>
              <i className="icon-upload bi bi-cloud-upload"></i>
            </div>
            <div className='btn btn-light float-end' title="Exportar conversaciones" onClick={ () => {exportToExcel(conversations) }}>
              <i className="icon-upload bi bi-file-earmark-arrow-down"></i>
            </div>
          </div>
        </div>

        <div className='row px-3 animate__animated animate__fadeIn d-flex'>
          <div className='w-100 my-4'>
            <div className='border mb-2 animate__animated animate__fadeIn'></div>
          </div>
        </div>

        <div className='row px-3 animate__animated animate__fadeIn d-flex'>
          <AdvancedFilters 
            selectedFecha={filterFecha} 
            setSelectedFecha={setFilterFecha} 
            selectedEstado={filterEstado} 
            setSelectedEstado={setFilterEstado} 
            selectedRenataState={filterRenataState}
            setSelectedRenataState={setFilterRenataState}
            selectedCategory={filterCategory}
            setSelectedCategory={setFilterCategory}
          />
        </div>
        
        <div className='row px-3 animate__animated animate__fadeIn d-flex'>
          <div className='w-100 my-4'>
            <div className='border mb-2 animate__animated animate__fadeIn'></div>
          </div>
        </div>
        

          <div className='row px-4 animate__animated animate__fadeIn mb-5'>
            <div className='col-9 ps-0'>
              <input 
              type="text" 
              className={'form-control bg-light'} 
              placeholder='Buscar...  '
              onChange={handleSearchChange}
              />
            </div>
            <div className='col-3 pe-0'>
              <button className='btn btn-light w-100' onClick={selectAll}>
                Seleccionar todo
              </button>
            </div>
            
          </div>


          {(filteredConversations.length == 0) && (
            <div className='row w-100 my-2 p-3 animate__animated animate__fadeIn'>
              <p>No se han encontrado conversaciones.</p>
            </div>
          )}
          {(filterCategory != null && filteredConversations.length > 0) && (
            <div className='row w-100 my-2 p-3 animate__animated animate__fadeIn'>
              <h1 className='fw-bold'>{categoryIcons[filterCategory]} {filterCategory}</h1>
            </div>
          )}
          <div className='ps-4'>
          {filteredConversations.length > 0 && filteredConversations.map((conversation_item, index) => ( 
            <div 
              key={index} 
              className={`row border-start border-2 w-100 my-2 p-3 conversation-item animate__animated animate__fadeIn ${selectedIds.includes(conversation_item.id) ? 'selected' : ''}`}
            >
              <div 
                onClick={() => {
                handleShowModal(conversation_item);
                }} 
                className="col-11 d-flex flex-wrap"
              >
                <h4 className={`${isActive(conversation_item.last_message_date) ? 'text-success' : 'text-secondary'}  mb-0 fw-bolder me-3`}><i className='bi bi-circle-fill'></i></h4>
                <h4 className='text-dark mb-0 fw-bolder me-3'>{conversation_item.user_name}</h4>
                <h4 className='text-secondary mb-0 fw-bolder'>{conversation_item.user_phone}</h4>
                <h4 className='text-secondary mb-0 ms-3 fw-bolder'>
                  {conversation_item.renata_sleep ? "💤" : ""}
                </h4>
                {filterCategory == null && (
                  <h4 className='text-secondary mb-0 fw-bolder'>
                    {categoryIcons[conversation_item.category]} 
                  </h4>
                )}
                
              </div>
              <div className="col-1" onClick={() => { handleSelectConversation(conversation_item.id); }}>
                <h4 className="lead mt-1"><i className={` text-dark ${selectedIds.includes(conversation_item.id) ? 'bi bi-check-circle-fill' : 'hide bi bi-circle'}`}></i></h4>
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>

      <div className='w-100 my-5 py-5'>
        <div className='border-0 mb-2 animate__animated animate__fadeIn'></div>
      </div>

      <div className={selectedIds.length == 0 ? 'd-none' : 'btn-add text-end pe-4'}>
        <div className='btn btn-lg btn-dark px-3 rounded animate__animated animate__fadeIn' onClick={ () => {handleShowModalMensaje()}}>
          <p className='lead py-0 mb-0'><i className="bi bi-whatsapp me-2"></i> Enviar Mensaje</p>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>{opened_conversation?.user_name}</Modal.Title> {/* Ejemplo de cómo mostrar datos de la conversación abierta */}
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className="col-3">
              <p className='me-3 fw-bold'>Fecha</p>
            </div>
            <div className="col-9">
              <p className=''>{opened_conversation?.last_message_date}</p>
            </div>
          </div>
          <div className='row'>
            <div className="col-3">
              <p className='me-3 fw-bold'>Teléfono</p>
            </div>
            <div className="col-9">
              <p className=''>{opened_conversation?.user_phone}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-3'>
              <p className='me-3 fw-bold'>Renata</p>
            </div>
            <div className='col-6 text-start'>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={opened_conversation?.renata_sleep === false || opened_conversation?.renata_sleep === undefined}
                  onChange={handleRenataSleepChange} 
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-3">
              <p className='me-3 fw-bold'>Resumen</p>
            </div>
            <div className="col-9">
              <p className=''>{opened_conversation?.summary}</p>
            </div>
          </div>
          <div className="mt-5 d-flex justify-content-end">
            {opened_conversation?.threadId && opened_conversation.threadId.length > 0 && (
              <a href={"/messages?thread="+String(opened_conversation?.threadId) + "&name=" + opened_conversation?.user_name + "&phone=" + opened_conversation?.user_phone + "&summary=" + opened_conversation?.summary} className="btn btn-dark w-100"> Ver conversación </a>
            )}
          </div>

        </Modal.Body>        
      </Modal>

      <Modal show={showModalCarga} onHide={handleCloseModalCarga}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>🔗 Cargar Conversaciones</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <div className='row mb-3'>
            <div className="col-12">
              <p className='me-3 mb-0 fw-bold'>Formato</p>
              <a href='#' onClick={exportExampleFormat} className='text-primary'>Descarga el formato para carga masiva</a>
            </div>
          </div>
          <div className='row'>
            <div className="col-12">
              <p className='me-3 mb-0 fw-bold'>Archivo</p>
            </div>
          </div>
          

          <div className="row">
            <div className="mb-3">
              <label className="form-label">Carga el archivo con el formato de Renata</label>
              <input className="form-control" type="file" id="formFile" onChange={(e) => {
                // Llama a handleFileUpload y proporciona una función callback para actualizar el estado
                handleFileUpload(e, (data) => {
                  setUploadedConversations(data);
                });
              }}></input>
            </div>
          </div>

        </Modal.Body>   
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowModalCarga(false)}>
            Cancelar
          </Button>
          <Button variant="dark px-3 w-25" onClick={uploadConversations}>
            {isLoading ? <img src={loadingGif} alt="Cargando..." style={{width: '20px', marginRight: '10px'}}/> : null}
            {buttonText}
          </Button>
        </Modal.Footer>         
      </Modal>


      <Modal show={showModalMensaje} onHide={handleCloseModalMensaje}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>💬 Enviar Mensaje</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          
          <div className='row mb-1'>
            <div className="col-12 align-items-center">
              <p className='lead mb-1 fw-bold'>Para</p>
            </div>
          </div>

          {/* Iterar sobre las conversaciones seleccionadas */}
          {selectedConversations.map((conv, index) => (
            <div key={index} className='row border mx-0 d-flex align-items-center p-2 rounded mb-1 bg-white'>
              <div className="col-11 d-flex align-items-center">
                <p className={`${isConversationActive(conv) ? 'text-success' : 'text-secondary'}  mb-0 fw-bolder me-3`}><i className='bi bi-circle-fill'></i></p>
                <p className='mb-0 me-3 fw-bold'>{conv.user_name}</p>
                <p className='mb-0 ms-3'>{conv.user_phone}</p>
              </div>
              <div className="col-1 d-flex align-items-center justify-content-center">
                {selectedConversations.length > 1 && (
                  <i className="bi bi-x-circle-fill text-secondary" onClick={() => handleSelectConversation(conv.id)} style={{cursor: 'pointer'}}></i>
                )}
              </div>
            </div>
          ))}

          {/* Mostrar mensaje de advertencia o área de texto basado en el estado de la conversación */}
          {hasInactiveConversation ? (
            <div className='w-100 p-0'>
              <p className='mt-3'>Al menos una de las conversaciones seleccionadas es inactiva. No se pueden enviar mensajes customizados a conversaciones inactivas. Puedes mandar un mensaje de plantilla.</p>
              <hr />
              <div className='mt-3 p-5 bg-white border rounded' style={{ position: 'relative' }}>
                <h5>Plantilla</h5>
                <p className='align-items-center' style={{ marginBottom: 0 }}>
                  Hola, te escribe Renata de 
                  <i className='text-secondary fw-bold ms-1' style={{ wordBreak: 'break-word', maxWidth: '100%' }}>
                    {messageToSend || '(mensaje)'}
                  </i>
                </p>
                <input 
                  type="text" 
                  name="renata_template" 
                  className='form-control bg-light mt-3' 
                  placeholder='Introduce el parámetro del mensaje' 
                  value={messageToSend} 
                  onChange={handleMessageChange} 
                />
              </div>

              
            </div>
            
            ) : (
            <div className='row mt-4'>
              <div className="col-12 align-items-center">
                <p className='lead mb-1 fw-bold'>Mensaje</p>
                <p className='mb-2'>Ingresa el mensaje que deseas que Renata envíe</p>
                <textarea
                  className="form-control mb-2"
                  placeholder="Mensaje para enviar"
                  rows={3} 
                  onChange={handleMessageChange}
                  value={messageToSend}
                ></textarea>
              </div>
            </div>
          )}

        </Modal.Body>   
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowModalMensaje(false)}>
            Cancelar
          </Button>
          
          {hasInactiveConversation ? 
          ( 
            <Button
              variant="dark px-3 w-25"
              onClick={sendTemplateMessage}
              disabled={messageToSend.trim().length === 0} // Deshabilita el botón si el mensaje está vacío o hay conversaciones inactivas
            >
            {isLoading ? <img src={loadingGif} alt="Enviando..." style={{width: '20px', marginRight: '10px'}}/> : "Enviar"}
            </Button>
          
          ) : (
            <Button
              variant="dark px-3 w-25"
              onClick={sendMessages}
              disabled={messageToSend.trim().length === 0} // Deshabilita el botón si el mensaje está vacío o hay conversaciones inactivas
            >
            {isLoading ? <img src={loadingGif} alt="Enviando..." style={{width: '20px', marginRight: '10px'}}/> : "Enviar"}
            </Button>
          )}
        
        </Modal.Footer>     
      </Modal>


      <Menu show={show} handleClose={handleClose} />
    </div>
  );
}

export default MyConversations;
