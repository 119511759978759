import React from 'react';
import { Button, Navbar } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons
import './css/main.css';
import './css/topbar.css';
import Logo from './img/renata-logo.svg';

interface TopBarProps {
  handleShow: () => void;
}

function TopBar({handleShow}: TopBarProps) {
  return (
    <Navbar className="px-3 py-2 navbar-glass" expand="lg">
      <Navbar.Brand href="/">
        <img className="logo-size" src={Logo} alt="Renata Logo" />
      </Navbar.Brand>
      <div className="ms-auto">
        <div className="btn btn-lg bg-transparent btn-light border border-2 rounded" onClick={handleShow}>
          <i className="bi bi-list" style={{ fontSize: '1.2em' }}></i> 
        </div>
      </div>
    </Navbar>
  );
}

export default TopBar;
