import React, { useState, ChangeEvent } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Logo from './img/renata-logo.svg';
import loadingGif from './img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/login.css'; // You might want to rename this CSS file if it's used for both login and registration

function Register() {
    const [username, setUsername] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false); 
    const [isValidNumber, setIsValidNumber] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const navigate = useNavigate(); // Variable para controlar la redirección

    const registerUser = async () => {
        try {
          const response = await axios.post('https://development2.goji-mx.cloud/backend/register', {
            username,
            phoneNumber,
            password
          });
    
          // Si el servidor responde con un código 201 mostrar mensaje de éxito
          if (response.status === 201) {
            alert("Registro exitoso!");
            navigate("/login");
          }
        } catch (error: any) {
          console.error('Error al registrar el usuario', error);
    
          // Mostrar un mensaje basado en el error
          if (error.response) {
            alert(`Error en el registro: ${error.response.data}`);
          } else if (error.request) {
            // La solicitud fue hecha pero no se recibió respuesta
            console.error("No se recibió respuesta: ", error.request);
            alert("Error en el registro: No se recibió respuesta del servidor.");
          } else {
            // Algo diferente
            console.error("Error: ", error.message);
            alert("Error en el registro: " + error.message);
          }
        }
    };
    

    const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
  
        // Remove non-digit characters
        value = value.replace(/\D/g, '');
      
        // Check prefix and format accordingly
        const prefix = value.slice(0, 2);
        if (prefix === '55' || prefix === '56' || prefix === '33' || prefix === '81') {
          if (value.length > 2 && value.length <= 6) {
            value = value.slice(0, 2) + ' ' + value.slice(2);
          } else if (value.length > 6) {
            value = value.slice(0, 2) + ' ' + value.slice(2, 6) + ' ' + value.slice(6, 10);
          }
        } else {
          if (value.length > 3 && value.length <= 6) {
            value = value.slice(0, 3) + ' ' + value.slice(3);
          } else if (value.length > 6) {
            value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6, 10);
          }
        }
      
        // Update state
        setPhoneNumber(value);
        setPhoneError(false);
        setIsValidNumber(value.length === 12);
    };

    const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
        setUsernameError(false);
    };

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setPasswordError(false);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let valid = true;
        setIsLoading(true);

        if (!username.trim()) {
            setUsernameError(true);
            valid = false;
        } else {
            setUsernameError(false);
        }

        if (!isValidNumber) {
            setPhoneError(true);
            valid = false;
        } else {
            setPhoneError(false);
        }

        if (password.length < 5) {
            setPasswordError(true);
            valid = false;
        } else {
            setPasswordError(false);
        }

        if (!valid) {
            setIsLoading(false);
            return;
        }

        await registerUser();

        setTimeout(() => {
            setIsLoading(false);
        }, 1000); 
    };
    

  return (
    <div className='flex-column align-items-center py-3 px-5'>
      <div className='row justify-content-center'>
        <div className='col-sm-12 col-md-5 col-lg-4 text-center'>
          <img className="w-50 mt-5" src={Logo} alt="Renata Logo" />
        </div>
      </div>
      
      <div className='row justify-content-center'>
        <div className="col-sm-12 col-md-4 col-lg-3 p-login">
          <h1 className='fw-bolder text-dark text-center mb-4 animate__animated animate__fadeInUp'>Regístrate</h1>
          <form noValidate className='w-100 p-0 animate__animated animate__fadeInUp' onSubmit={handleSubmit}>
                <div className="mb-3">
                    <input 
                        id='name'
                        type="text" 
                        className={`form-control bg-light centered-placeholder centered-input-container d-flex ${usernameError ? 'is-invalid' : ''}`}
                        placeholder='Nombre completo' 
                        onChange={handleUsernameChange}
                        value={username}
                        autoComplete="off"
                    />
                    {usernameError && <div className="invalid-feedback">Por favor, introduce un nombre.</div>}
                </div>
                <div className="mb-3">
                    <input 
                        id='tel'
                        type="tel" 
                        className={`form-control bg-light centered-placeholder centered-input-container d-flex ${phoneError ? 'is-invalid' : ''}`}
                        placeholder='Número de teléfono' 
                        onChange={handlePhoneChange}
                        value={phoneNumber}
                        autoComplete="off"
                    />
                    {phoneError && <div className="invalid-feedback">Por favor, introduce un teléfono válido.</div>}
                </div>
                <div className="mb-3">
                    {isValidNumber && (
                        <input 
                        id='password'
                        type="password" 
                        className={`form-control bg-light  centered-placeholder centered-input-container d-flex ${passwordError ? 'is-invalid' : ''}`}
                        placeholder='Contraseña' 
                        onChange={handlePasswordChange}
                        autoComplete="off"
                    />
                    )}
                    {passwordError && <div className="invalid-feedback">La contraseña debe tener al menos 5 caracteres.</div>}
                </div>
                <button type="submit" className="btn btn-dark w-100" disabled={isLoading}>
                    {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..."/> : 'Registrarse'}
                </button>
                <div className='mt-2 text-center'>
                    <span className='fs-6'>¿Ya tienes una cuenta? <Link to="/login" className="text-primary">Inicia sesión</Link></span>
                </div>
            </form>
        </div>
      </div>
    </div>
  );
}

export default Register;
