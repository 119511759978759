import React, { useState, useEffect } from 'react';
import './css/main.css';
import Menu from './Menu';
import TopBar from './TopBar';
import loadingGif from './img/loading.gif';
import { Button, Modal,  } from 'react-bootstrap';
import axios from 'axios'; 

interface Option {
  option_name: string,
  description: string,
  icon: string
}

interface Category {
  category_name: string,
  options: Array<Option>;
}

function Settings() {

  const gojimx_token = localStorage.getItem('gojimx_token'); 
  const gojimx_phone = localStorage.getItem('gojimx_phone'); 
  const gojimx_username = localStorage.getItem('gojimx_username'); 
  const gojimx_businessId = localStorage.getItem('gojimx_businessId'); 
  const gojimx_business_name = localStorage.getItem('gojimx_business_name'); 
  const gojimx_assistant_id = localStorage.getItem('gojimx_assistant_id'); 

  /*Tab State */
  const [activeTab, setActiveTab] = useState('Cuenta');

  const [isLoading, setIsLoading] = useState(false);
  const [whatsapp_number, setWhatsapp_number] = useState('');
  const [whatsapp_businessID, setWhatsapp_businessID] = useState('');
  const [renataID, setRenataID] = useState('');
  const [consultorio, setConsultorio] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [horario, setHorario] = useState('');
  const [contextoAdicional, setContextoAdicional] = useState('');
  
  /* Modales */
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showAddCategoriaModal, setShowAddCategoriaModal] = useState(false);

  /* Categories State */
  const [categorias, setCategorias] = useState<Category[]>([]);
  const [originalOptionName, setOriginalOptionName] = useState('');
  const [newOptionName, setNewOptionName] = useState('');
  const [newOptionDescription, setNewOptionDescription] = useState('');
  const [newOptionIcon, setNewOptionIcon] = useState('');
  const [titleModalAdd, setTitleModalAdd] = useState('');

  const handleNewOptionNameChange = (event: { target: { value: React.SetStateAction<string>; }; }) => setNewOptionName(event.target.value);
  const handleNewOptionDescriptionChange = (event: { target: { value: React.SetStateAction<string>; }; }) => setNewOptionDescription(event.target.value);
  const handleNewOptionIconChange = (event: { target: { value: React.SetStateAction<string>; }; }) => setNewOptionIcon(event.target.value);

  const addNewOptionToDefaultCategory = async () => {
    setIsLoading(true); // Use your existing loading state to indicate loading

    try {
        const response = await axios.post(`https://development2.goji-mx.cloud/backend/add_option_to_category/${gojimx_businessId}`, {
            option_name: newOptionName,
            description: newOptionDescription,
            icon: newOptionIcon
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${gojimx_token}`
            }
        });

        if (response.status === 200) {
            // Success - Handle accordingly, maybe refresh the categories list
            handleCloseAddCategoriaModal()
            setShowConfirmationModal(true);
            fetchCategories(); // Assuming this function fetches the updated list of categories
        }
    } catch (error) {
        console.error('Error adding new option:', error);
        // Handle the error, maybe display a message to the user
    }

    setIsLoading(false);
};


  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleCloseAddCategoriaModal = () => {
    setShowAddCategoriaModal(false);
    setNewOptionName("");
    setNewOptionDescription("");
    setNewOptionIcon("");
  };

  const handleAddCategoria = () => {
    setTitleModalAdd("Agregar Categoría");
    setShowAddCategoriaModal(true);
  }

  const handleEditCategoria = (option_data: Option ) => {
    setTitleModalAdd("Editar Categoría");
    setShowAddCategoriaModal(true);
    setOriginalOptionName(option_data.option_name);

    setNewOptionName(option_data.option_name);
    setNewOptionDescription(option_data.description);
    setNewOptionIcon(option_data.icon);
  }

  const handleConsultorioChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setConsultorio(event.target.value);
  };

  const handleDoctorNameChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setDoctorName(event.target.value);
  };

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.patch('https://development2.goji-mx.cloud/backend/updateBusiness', {
        business_phone: whatsapp_number,
        new_business_name: consultorio,
        new_username: doctorName,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + gojimx_token, // Replace tuTokenJWT with your actual token
        }
      });

      if (response.status === 200) {
        setShowConfirmationModal(true);
        localStorage.setItem('gojimx_business_name', consultorio); 
        localStorage.setItem('gojimx_username', doctorName); 
      }
    } catch (error) {
      // Handle error
      alert("No se ha podido guardar la configuración :(")
      console.error('Error updating business name:', error);
    }

    setIsLoading(false);
  };

  const handleTabChange = (tab: React.SetStateAction<string>) => {
    setActiveTab(tab);
  };

  const fetchCategories = () => {
    axios.get(`https://development2.goji-mx.cloud/backend/get_category/${gojimx_businessId}`, {
      headers: {
        Authorization: `Bearer ${gojimx_token}`
      }
    })
      .then(response => {
          setCategorias(response.data[0]);
      })
      .catch(error => {
        console.error('There was an error fetching the categories:', error);
      });
  };

  const handleDeleteOption = async (optionName: any) => {
    setIsLoading(true);
    
    try {
      const response = await axios.delete(`https://development2.goji-mx.cloud/backend/delete_option_from_category/${gojimx_businessId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${gojimx_token}`
        },
        data: { // Axios DELETE requests send data in the 'data' key
          option_name: optionName
        }
      });
  
      if (response.status === 200) {
        handleCloseAddCategoriaModal();
        setShowConfirmationModal(true);
        
        fetchCategories(); // Refresh the categories from the backend or update state directly
      }
    } catch (error) {
      console.error('Error deleting option:', error);
      // Optionally handle the error, e.g., by showing an error message
    }
  
    setIsLoading(false);
  };

  const editOptionInDefaultCategory = async () => {
    setIsLoading(true); // Use your existing loading state to indicate loading
  
    try {
        const response = await axios.patch(`https://development2.goji-mx.cloud/backend/edit_option_in_category/${gojimx_businessId}`, {
            original_option_name: originalOptionName,
            new_option_name: newOptionName,
            description: newOptionDescription,
            icon: newOptionIcon
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${gojimx_token}`
            }
        });
  
        if (response.status === 200) {
            // Success - Handle accordingly, maybe refresh the categories list
            handleCloseAddCategoriaModal();
            setShowConfirmationModal(true);
            fetchCategories(); // Assuming this function fetches the updated list of categories
        }
    } catch (error) {
        console.error('Error editing option:', error);
        // Handle the error, maybe display a message to the user
    }
  
    setIsLoading(false);
  };
  
  
  useEffect(() => {

    fetchCategories();

    setConsultorio(String(gojimx_business_name));
    setDoctorName(String(gojimx_username));
    setHorario(String("gojimx_horario"));
    setContextoAdicional(String("gojimx_contexto"));
    setWhatsapp_number(String(gojimx_phone));
    setWhatsapp_businessID(String(gojimx_businessId));
    setRenataID(String(gojimx_assistant_id));
  }, []);

  return (
    <div className={show ? 'blur-effect pt-main' : 'pt-main'}>
      <div id="topbar">
        <TopBar handleShow={handleShow}/>
      </div>
      
      <div className='row justify-content-center'>
        <div className='col-lg-6 col-md-8 col-sm-12 py-4'>
          <div className='row px-3 animate__animated animate__fadeIn'>
            <h1 className='fw-bold text-dark mt-0'>Configuración</h1>
          </div>

          <div className='row mx-3 animate__animated animate__fadeIn mb-4'>
            <ul className="nav justify-content-start">
              <li className="nav-item border-radius-custom-1 bg-light">
                <button 
                  className={activeTab == "Cuenta"  ? 'btn btn-lg border-0 btn-dark rounded-pill' : 'btn btn-lg border-0 btn-light rounded-pill'}
                  onClick={() => handleTabChange('Cuenta')}
                >Cuenta</button>
              </li>
              <li className="nav-item border-radius-custom-2 bg-light">
                <button 
                  className={activeTab == "Categorias"  ? 'btn btn-lg border-0 btn-dark rounded-pill' : 'btn btn-lg border-0 btn-light rounded-pill'}
                  onClick={() => handleTabChange('Categorias')}
                >Categorías</button>
              </li>
            </ul>
          </div>
          
          {/* CUENTA TAB */}
          { activeTab == "Cuenta" && (
            <form onSubmit={handleSubmit}>
          
          <div className='row px-3 animate__animated animate__fadeIn mb-3'>
            <h5 className='fw-bold text-dark my-0'>Nombre del consultorio</h5>
            <p className='text-dark mt-0 mb-2'>Renata lo utiliza para dar información</p>
            <div>
              <input 
                  type="text" 
                  className={'form-control bg-light'}
                  placeholder='Nombre del consultorio'
                  defaultValue={consultorio}
                  autoComplete="off"
                  onChange={handleConsultorioChange}
              />
            </div>
          </div>
          <div className='row px-3 animate__animated animate__fadeIn mb-3'>
            <h5 className='fw-bold text-dark my-0'>Nombre del doctor</h5>
            <p className='text-dark mt-0 mb-2'>Nombre completo con el que te identificas</p>
            <div>
              <input 
                  type="text" 
                  className={'form-control bg-light'}
                  placeholder='Nombre del doctor' 
                  autoComplete="off"
                  defaultValue={doctorName}
                  onChange={handleDoctorNameChange}
              />
            </div>
          </div>

          {/* 
          <div className='row px-3 animate__animated animate__fadeIn mb-3'>
            <h5 className='fw-bold text-dark my-0'>Horario de atención</h5>
            <p className='text-dark mt-0 mb-2'>Indica los horarios generales del consultorio</p>
            <div>
              <input 
                  type="text" 
                  className={'form-control bg-light'}
                  placeholder='Horario de atención' 
                  autoComplete="off"
                  defaultValue={horario}
              />
            </div>
          </div>
          <div className='row px-3 animate__animated animate__fadeIn mb-3'>
            <h5 className='fw-bold text-dark my-0'>Contexto adicional</h5>
            <p className='text-dark mt-0 mb-2'>Ingresa datos extra que necesita conocer Renata</p>
            <div>
              <textarea
                  className={'form-control bg-light'}
                  placeholder='Contexto adicional' 
                  autoComplete="off"
                  rows={3}
                  defaultValue={contextoAdicional}
              />
            </div>
          </div>

          */}

          <div className='w-100 my-4'>
            <div className='border mb-2 animate__animated animate__fadeIn'></div>
          </div>

          <div className='row px-3 animate__animated animate__fadeIn mb-3'>
            <h5 className='fw-bold text-dark mt-0 mb-2'>Teléfono de WhatsApp</h5>
            <div>
              <input 
                  type="text" 
                  className={'form-control bg-light'}
                  placeholder={whatsapp_number}
                  autoComplete="off"
                  disabled
              />
            </div>
          </div>
          <div className='row px-3 animate__animated animate__fadeIn mb-3'>
            <h5 className='fw-bold text-dark mt-0 mb-2'>BussinessID</h5>
            <div>
              <input 
                  type="text" 
                  className={'form-control bg-light'}
                  placeholder={whatsapp_businessID}
                  autoComplete="off"
                  disabled
              />
            </div>
          </div>
          <div className='row px-3 animate__animated animate__fadeIn mb-3'>
            <h5 className='fw-bold text-dark mt-0 mb-2'>RenataID</h5>
            <div>
              <input 
                  type="text" 
                  className={'form-control bg-light'}
                  placeholder={renataID}
                  autoComplete="off"
                  disabled
              />
            </div>
          </div>

          <div className='row px-3 animate__animated animate__fadeIn mb-3 mt-5'>
            <div>
              {(gojimx_username != doctorName ||  gojimx_business_name != consultorio) && (
              <button type="submit" className="btn btn-dark w-100" disabled={isLoading}>
                {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..."/> : 'Guardar'}
              </button>
              )}
              
            </div>
            
          </div>
         
        </form>
          )} 

          {/* CATEGORIAS TAB */}
          { activeTab == "Categorias" && (

            <div className='row px-3 animate__animated animate__fadeIn mb-3'>
              <h5 className='fw-bold text-dark my-0'>Categorías actuales</h5>
              <p className='text-dark mt-0 mb-3'>Renata lo utilizará para segmentar las conversaciones, puedes agregar hasta 6.</p>

              <div className='d-block w-100'>

                <div className="row px-2">
                {categorias.length > 0 && categorias.map((categoria) =>
                  categoria.category_name === "default" ? (
                    categoria.options.length > 0 && categoria.options.map((option) => (

                      <div key={option.option_name} className='btn bg-light p-3 me-3 mb-3 col-lg-3 col-md-5 col-sm-11 custom-height-categoria' onClick={ () => {handleEditCategoria(option)}}>
                        <h1>{option.icon}</h1>
                        <span>{option.option_name}</span>
                      </div>



                    ))
                  ) : null  // Return null or an alternative component for categories not matching "default"
                )}

                {categorias[0].options.length < 6 && (
                  <div className='btn bg-light p-3 me-3 mb-3 col-lg-3 col-md-5 col-sm-11 custom-height-categoria' onClick={handleAddCategoria}>
                    <h1><i className="bi bi-plus-lg"></i></h1>
                    <span>Agregar nueva</span>
                  </div>
                )}


                </div>

              </div>
              
            </div>
          
          )}
          

        </div>
      </div>

      <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>✅ Configuración</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Los datos han sido guardados correctamente.
        </Modal.Body>
      </Modal>

      <Modal show={showAddCategoriaModal} onHide={handleCloseAddCategoriaModal}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>{titleModalAdd}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          {/* Option Name Input */}
          <h5 className='fw-bold text-dark mb-2'>Nombre de la Opción</h5>
          <p className='text-dark mb-2'>Introduce el nombre de la nueva opción</p>
          <div>
            <input 
                type="text" 
                className="form-control mb-3"
                placeholder="Ej. Atención Urgente" 
                value={newOptionName}
                onChange={handleNewOptionNameChange}
            />
          </div>
          
          {/* Option Description Input */}
          <h5 className='fw-bold text-dark mb-2'>Descripción</h5>
          <p className='text-dark mb-2'>Detalla lo que representa esta opción</p>
          <div>
            <textarea
              className="form-control mb-3"
              placeholder="Ej. Consultas que requieren atención inmediata..."
              rows={3}
              value={newOptionDescription}
              onChange={handleNewOptionDescriptionChange}
            ></textarea>
          </div>

          {/* Option Icon Input */}
          <h5 className='fw-bold text-dark mb-2'>Ícono</h5>
          <p className='text-dark mb-2'>Elige un ícono para representar visualmente esta opción</p>
          <div className='d-flex'>
            <select 
              className="form-select mb-3" 
              aria-label="Default select example"
              value={newOptionIcon}
              onChange={handleNewOptionIconChange}
            >
              <option value="">Selecciona</option>
              <option value="🔴">🔴</option>
              <option value="🟡">🟡</option>
              <option value="🟢">🟢</option>
              <option value="🔵">🔵</option>
              <option value="🚨">🚨</option>
              <option value="💯">💯</option>
              <option value="👍">👍</option>
              <option value="👎">👎</option>
              <option value="🔥">🔥</option>
              <option value="😄">😄</option>
              <option value="😞">😞</option>
              <option value="😐">😐</option>
              <option value="😠">😠</option>
            </select>
          </div>

        </Modal.Body>

        <Modal.Footer>
          {titleModalAdd == "Editar Categoría" && (
            <div className="w-50">
              <div className="col-1">
                <Button className="btn btn-lg bg-transparent border-0" onClick={() => handleDeleteOption(newOptionName)}>
                  <i className="bi bi-trash3 text-danger"></i>
                </Button>
              </div>
            </div>
          )}
          <Button variant="light" onClick={handleCloseAddCategoriaModal}>
            Cancelar
          </Button>

          {titleModalAdd == "Editar Categoría" && (
            <Button variant="dark px-3 w-25" onClick={editOptionInDefaultCategory} disabled={isLoading}>
                {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..."/> : 'Actualizar'}
            </Button>
          )}

          {titleModalAdd == "Agregar Categoría" && (
            <Button variant="dark px-3 w-25" onClick={addNewOptionToDefaultCategory} disabled={isLoading}>
                {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..."/> : 'Guardar'}
            </Button>
          )}

        </Modal.Footer>
      </Modal>

      <Menu show={show} handleClose={handleClose} />
    </div>
  );
}

export default Settings;
