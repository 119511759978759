import axios from 'axios';

const environment = "development2";

export default function handleLogout() {
    localStorage.removeItem('gojimx_token');
    localStorage.removeItem('gojimx_phone');
    localStorage.removeItem('gojimx_username');
    localStorage.removeItem('gojimx_businessId');
    localStorage.removeItem('gojimx_business_name');
    localStorage.removeItem('gojimx_assistant_id');

    window.location.reload();
};

export function decodeUnicode(str: string) {
    try {
        str = decodeURIComponent(str);
    } catch (e) {
        console.log('Error in URI decoding:', e);
    }

    str = str.replace(/\\u([\dA-F]{4})/gi, (match, grp) => {
        return String.fromCharCode(parseInt(grp, 16));
    });

    // Regular expression to keep standard ASCII characters and specific Spanish characters
    str = str.replace(/[^\x20-\x7EáéíóúÁÉÍÓÚñÑüÜ]/g, '');

    return str;
}



export async function getWeekAppointments(selectedDate: string, businessId: string) {
    try {
        const gojimx_token = localStorage.getItem('gojimx_token');

        const response = await axios.get(`https://${environment}.goji-mx.cloud/backend/week_appointments/${selectedDate}?business_id=${businessId}`, {
            headers: {
              Authorization: `Bearer ${gojimx_token}`
            }
          });
        console.log('[Client] Week appointments retrieved successfully.');
        return response.data;
    } catch (error) {
        console.error('[Client ERROR] Error getting week appointments:', error);
        throw error;
    }
}

export async function getAppointments(date: string, businessId: string) {
    try {
        const gojimx_token = localStorage.getItem('gojimx_token');

        const response = await axios.get(`https://${environment}.goji-mx.cloud/backend/appointments?date=${date}&businessId=${businessId}`, {
      headers: {
        Authorization: `Bearer ${gojimx_token}`
      }
    });

        console.log('[Client] Appointments retrieved successfully.');
        return response.data;
    } catch (error) {
        console.error('[Client ERROR] Error getting appointments:', error);
        throw error;
    }
}

export async function validateToken() {
    const token = localStorage.getItem('gojimx_token');
    if (!token) {
        console.log('Token not found');
        handleLogout();
        return false; // Token no encontrado, retorna false
    }

    try {
        const response = await axios.get(`https://${environment}.goji-mx.cloud/backend/validateToken`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        // Si la validación es exitosa, retorna true
        return true;
    } catch (error) {
        console.log('Token validation failed:', error);
        handleLogout();
        return false; // Error en la validación del token, retorna false
    }
}
